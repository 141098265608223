$themeColor: rgb(232, 235, 232);
//#E46F44;
//#eff198;
$triptopperyellow:#eff198;
$sidebarwidth:260px;
$btnthemeColor:blue;
$sidebarbackgroundcolor: #f6f6f7;
$themeyellow:smokewhite;
// #eff198;
$themetext: white;
//rgb(67, 66, 66);
$lightgray: #EFEEEA;
$gray: #F7F7F8;
$black : rgb(0, 0, 0);
$white:#FFFFFF;
$smoothblack :rgba(0, 0, 0, 0.7);
$iconcolorgray:#CCCCC7;

$activebgcolor:rgb(247, 236, 221);

$iconcolorSmoothBlack :#5F5F5F;

// html {
//   scroll-behavior: auto !important;
// }

.facebookloginbtnholder
{
  width: 100%;
  height: 40px;
  // border: none;
  // font-weight: bold;
  // color: $white;
  font-size: 15px;
  background-color: #4c69ba;
}

.fa-facebook
{ 
   float: left;
   padding: 7px;


}
.nav-link
{
  font-weight: 800;
  color: black;
}
.validation-error{
  font-weight: 500;
  text-transform:initial;
  font-size: 14px;
  color: red;
}

.border-radius-nav{
  border-radius: 50px;
}
/* sidebar */
.sidebar
{
  width: $sidebarwidth;
  height:100vh;
  background-color: $sidebarbackgroundcolor;
  border-right: dashed 1px lightgray;
  top: 0;
}

.drawer{
  width: $sidebarwidth;
}

a{
  cursor: pointer;
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
a:hover{
  cursor: pointer;
}
.bg-sidebar
{
  background-color: $white;
  border-right: thin solid $lightgray;
  //$sidebarbackgroundcolor;
}
.content
{
  min-height: 100vh;
  margin-left: $sidebarwidth;

}

.marginSidebar{
  margin-left: $sidebarwidth;
}
body { 
  color: rgb(43, 44, 44);
}
.oswald
{
  font-family: 'Oswald', sans-serif;
}
.Bebas-Neue{ font-family: 'Bebas Neue', cursive;
}
.font-meansweb
{
  font-family: "Means Web", Georgia, Times, "Times New Roman", serif;
}
.App {
  color: rgb(46, 45, 45);
}

.themetag
{ background-color: $themeColor;}



.divider{
border-bottom: 1px dashed rgb(228, 227, 227);
}

.underline {
  position: relative;
  display: inline-block !important;
}

.underline::before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  height: 7px;
  width: 100% !important;
  border: solid 8px $themeColor;
  border-color: $themeColor transparent transparent transparent;
  border-radius: 50%;
}

.underline-theme {
  position: relative;
  display: inline-block !important;
}

.underline-theme::before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  height: 7px;
  width: 100% !important;
  border: solid 8px $triptopperyellow;
  border-color: $triptopperyellow transparent transparent transparent;
  border-radius: 50%;
}

.itinImg-container
{
  width: 70%;
  border-radius: 10px;
}

.itinImg
{
  border-radius: 10px;
  border: thin solid lightgray;
  width: 100%;
  max-height: 300px;
  height: auto;
}

.cursor-pointer{
  cursor: pointer;
}

.itinImg-md
{
  border-radius: 12px;
  // border: thin solid gray;
  width: 750px;
  max-height: 500px;
  height: auto;
}

.unsplash-search-img
{
  border-radius: 10px;
  border: thin solid lightgray;
  width: 100%;
  height: 250px;
}

.unsplash-text
{
  color: gray;
  font-size: 8px;
  text-align:end;
  width: 69%;
  margin-top: 2px;
  margin-bottom: 10px;

}

.unsplash-link
{
  color: gray;
  font-size: 9px;
}

.text-blue
{
  color: lightblue
}

.text-pink
{
  color:#FF416C
  
}
.text-sm
{
  font-size: 12px;
}
.custom-large-box
{
  border: none;
  font-size: 20px;
  font-weight: 600;
  background-color: $gray;
}

.borderless-input
{
  border: none;
  font-size:20px;
  font-weight: 600;
  text-align-last: right;
  width: 9ch;
  // width: 100%;
  background-color: transparent;
}
.borderless-input:focus{
  outline: none;
}
.borderless-input-sm
{
  border: none;
  font-size:16px;
  
  width: 50%;
  background-color: transparent;
}
.borderless-input-sm:focus{
  outline: none;
}

.hero1
{
  font-size: 80px;
  line-height: 100px;
  font-weight: 500;
}

.hero2
{
  font-size: 50px;
  font-weight: 900;
}
.hero3
{
  font-size: 36px;
  font-weight: 900;
}
.hero4
{
  font-size: 30px;
  font-weight: 900;
}

.carousel-card
{
  justify-content: center;
  align-items: center;
  // background-color: $lightgray;
  height: 500px;
  // border-radius: 20px;
  padding-right: 40px;
  padding-left: 40px;
}

.pac-container
{
  border: none;
  margin-top: -10px;
}
.pac-item{
  padding: 10px;
  font-size: 16px;
  
}
.pac-selected{
  background-color: $triptopperyellow;
}
.custom-small
{
  font-size: 9px;
}
.custom-sm-8px{
  font-size: 10px;
}
.custom-small-10px
{
  font-size: 12px;
}
/* CSS */
.button-63 {
  align-items: center;
  background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 19px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-63:active,
.button-63:hover {
  outline: 0;
}

#shareitinbtn
{
  display: none;
}

@media (min-width: 768px) {
  .button-63 {
    font-size: 24px;
    min-width: 196px;
  }
 
}
/*This is mobile*/
@media only screen and (max-width: 767px) {
  .content{
    margin-left: 0px;
    margin-top: 10px;

  }
 
  .hero1
  {
    font-size: 60px;
    line-height: 60px;
    font-weight: 900;
  }
  
  .hero2
  {
  font-size: 38px;
  font-weight: 900;
  }
  .hero3
  {
  font-size: 24px;
  font-weight: 900;
  }
  .hero4
{
  font-size: 20px;
  font-weight: 900;
}

  #shareitinbtn{
    display: block;
    position:fixed;
    bottom:0;
    height: 80px;
    font-size:30px;
    width:100%;
    font-weight:bold;
    z-index: 101;
    border:none ;
    background-color:  $themeColor;
  }

}

.selectable-div
{
  cursor: pointer;
}
.selectable-div-light
{
  cursor: pointer;
}
.selectable-div-light:hover
{
  background-color: whitesmoke;
}
.z-1{ z-index: 50;}

.margin-top-100
{
  margin-top: 100px;
}
.content-100
{
  min-height: 90vh;
}

.content-80
{
  min-height:80vh;
  margin-top: 100px;
}
.content-70
{
  min-height: 70vh;
}
.content-60
{
  min-height: 60vh;
}
.content-50
{
  min-height: 50vh;
}

.content-40
{
  min-height: 40vh;
}


.bg-theme
{
 
background-color: $gray;
color: $smoothblack;
/* */

}



.bg-active
{
  background-color: $activebgcolor;
}

.bg-pink
{
  background: #FF416C;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FF4B2B, #FF416C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}
.bg-dark
{
  background-color: #2d2d2d;
  background: #232526;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}

.bg-gray
{
  background:$gray;
}
.bg-pro{
  background-image: url(https://tt-public-static-assets.s3.ap-south-1.amazonaws.com/pro-hero.jpeg) ;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-white
{
  background-color: $white;
}
.bg-white-transparent
{
  background-color: rgba(255, 255, 255, 0.8);
}


.bg-yellow
{
  background-color: $triptopperyellow;
}
.yellow
{
  color: $triptopperyellow;
}
.gray
{
  color: rgb(118, 118, 118);
}

.bg-white
{
  background-color: whitesmoke;
}


.App-logo {
  height: 55px;
}

.App-logo-md
{
  height: 6vmin;
  pointer-events: none;
  color: white;
}
.triptopperlogo
{
  font-size: 20px;
  font-weight: 600;
  
}


.color-white
{
  color: white !important;
}
.color-black
{
  color:$smoothblack;
}

.navbar-light .navbar-nav .nav-link{
  color: $black;
  font-size: 12px;
}

.border-theme
{
  border-color: $themeColor !important;
}
.border-bold
{
  border: 2px solid $black !important;
}

.border-gray
{
  border-color: $lightgray ;
}

.custom-itin-rounded
{
  border-top-right-radius: 80px;
  border-top-left-radius: 80px;
  border: thin solid $gray;
}

.heading-roundebar
{

  border-bottom-right-radius: 80px;
  border-bottom-left-radius: 10px;
  background-color: $themeColor;

}

.hero-bg
{
  background-size: cover;
  background-repeat: no-repeat;
}

.dp-icon
{
  height: 30px;
  margin: 1vw;
}



.App-header {
  background-color:white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}




footer {
  background: $white;  /* fallback for old browsers */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

footer a {
  text-decoration: none;
  color: $smoothblack;
  font-weight: 600;
  font-size: 14px;
  transition-duration: 0.2s;
}



.copy {
  font-size: 12px;
  padding: 10px;
}

.footer-middle {
  padding-top: 2em;
}




/* Animation */

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}


.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}


/*  Animation ends */


/*SOCİAL İCONS*/

/* footer social icons */

ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
}

ul.social-network li {
  display: inline;
  margin: 0 5px;
}


/* footer social icons */

.social-network a.icoFacebook:hover {
  background-color: #3B5998;
}

.social-network a.icoLinkedin:hover {
  background-color: #007bb7;
}

.social-network a.icoFacebook:hover i,
.social-network a.icoLinkedin:hover i {
  color: #fff;
}

.social-network a.socialIcon:hover,
.socialHoverClass {
  color: #44BCDD;
}

.social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 15px;
}

.social-circle li i {
  margin: 0;
  line-height: 30px;
  text-align: center;
}

.social-circle li a:hover i,
.triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.social-circle i {
  color: #595959;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.social-network a {
  background-color: #F9F9F9;
}

.chkbx-lg
{
  height: 30px;
  width: 30px;
}



.cus-activity-tag
{
  margin: 3px;
  padding: 7px;
  line-height: 45px;
  font-size: 15px;
  white-space:nowrap;
  text-decoration: none;
}
.cus-adress
{
  line-height: 18px;
  font-size: 15px;
}

.activitybar
{
  border-bottom: thin solid whitesmoke;
}

.nowrap{white-space:nowrap;}

.nodecor
{
  text-decoration: none;
}

.solotag
{
  color: $iconcolorgray;
  font-size: 25px;
  font-weight: 600;
}
.solotag img{
  height: 28px;
  width: auto;
}

.btn-ttyellow
{
  background-color: $triptopperyellow;
  color: black;
  border-color: gray;
  font-weight: 700;
}

.btn-outline-black
{
  border-radius: 20px;
  color: gray;
  border:thin solid gray;
}
.btn-outline-gray
{
  color: $black;
  border:thin solid gray;
}
.btn-outline-gray:hover
{
  background-color: $gray;
}

.btn-check:active+.btn-outline-warning, .btn-check:checked+.btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show, .btn-outline-warning:active  {
  color: black;
  background-color: transparent;
  border-width: 2px;
  font-weight: 800;
  border-color: black;
}

.btn-check:active+.btn-outline-warning, .btn-check:checked+.btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show, .btn-outline-warning:hover  {
  color: black;
  background-color: transparent;
  border-width: 1px;
  // font-weight: 500;
  border-color: black;
}

//* Icon size classes*//
.i-xs
{
  height: 15px;
  width: auto;
  color: wheat;
  
}

.i-xs:hover
{
  -webkit-filter: brightness(70%);
	-moz-filter: brightness(70%);
	-o-filter: brightness(70%);
	-ms-filter: brightness(70%);
	filter: brightness(70%);
}

.i-sm
{
  height: 20px;
  width: auto;
  color: wheat;
  
}

.i-sm:hover
{
  -webkit-filter: brightness(70%);
	-moz-filter: brightness(70%);
	-o-filter: brightness(70%);
	-ms-filter: brightness(70%);
	filter: brightness(70%);
}


.i-md
{
  height: 28px;
  margin-bottom: 10px;
  width: auto;
}

.i-md:hover
{
  -webkit-filter: brightness(70%);
	-moz-filter: brightness(70%);
	-o-filter: brightness(70%);
	-ms-filter: brightness(70%);
	filter: brightness(70%);
}

.i-lg
{
  height: 40px;
  width: auto;
}
//* 


.centerDivider
{
  width: 100%; 
  height: 30px; 
  border-bottom: 1px dashed $lightgray; 
  padding:10px;
}
.centerDivider-text
{
  background-color: white;
}


//THEMES START
.height20
{
  height: 20px;
}
.radius-30
{
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}


.radius-bottom
{
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.theme-box
{
  height: 100px;
  width: 100%;
}
.theme-mountain
{
  background-image: url('/theme/mountain1.jpg');
  background-position-y: -300%;
  color: $white;
  a{
    color: $white;
  }
  .btn{
    color: $white;
  }
  .i-sm{
    filter: brightness(0) invert(1);
  }
  .i-xs{
    filter: brightness(0) invert(1);
  }

}
.theme-purple
{
  color: $white;
  background: #DA22FF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #9733EE, #DA22FF);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #9733EE, #DA22FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  a{
    color:white;
  }
  .i-sm{
    filter: brightness(0) invert(1);
  }
  .i-xs{
    filter: brightness(0) invert(1);
  }
  .btn{
    color: white;
  }
  
}

 

.theme-gray
{
  color: $smoothblack;
  background-color: $gray ;
}

.theme-yellow
{
  a{
    color: $black;
  }
  p{
    color: $black;
  }
  color: $smoothblack;
  background: #CAC531;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #F3F9A7, #CAC531);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #F3F9A7, #CAC531); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
.theme-dark
{
  color: $white;
  background:$smoothblack; 
  a{
    color:$white;
  }
  p{
    color: $white;
  }
  .i-sm{
    filter: brightness(0) invert(1);
  }
  .i-xs{
    filter: brightness(0) invert(1);
  }
  .btn{
    color: white;
  }
}

.theme-orange
{
  color: $white;

  background: #ff9966;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #ff5e62, #ff9966);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #ff5e62, #ff9966); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
.i-sm{
  filter: brightness(0) invert(1);
}
.i-xs{
  filter: brightness(0) invert(1);
}
.btn{
  color: white;
}
}
.theme-orange a
{
  color:$white;

}
.theme-blue-dark
{
  background: #4169E1;  /* fallback for old browsers */
color: $white;
a
{
  color: $white;
}
.i-sm{
  filter: brightness(0) invert(1);
}
.i-xs{
  filter: brightness(0) invert(1);
}
.btn{
  color: white;
}
}
.theme-coolsky
{
  background: #87CEFA;  /* fallback for old browsers */
  color: $black;
  a{
    color: black;
  }
  p{
    color: black;
  }
}

.theme-shifter
{
  background: #bc4e9c;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #f80759, #bc4e9c);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #f80759, #bc4e9c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
color: $white;
a{
  color: $white;
}
.i-sm{
  filter: brightness(0) invert(1);
}
.i-xs{
  filter: brightness(0) invert(1);
}
.btn{
  color: white;
}
}

.glass
{
  background: rgba(255,255,255,0.38);
 
}


// THEMES END


// ITIN VIEW START
.View
{
}

// ITIN VIEW END

//accordion

.accordion
{

}
.accordion-button:not(.collapsed)

{
  background-color: transparent;
  color: inherit;
}
.accordion-button:focus
{
 box-shadow: none;
 border-bottom: thin solid gray;

}

.day-drag-card{
  margin-top: -50px;
  height: 200px;
  background-color: white;
  border:1px solid rgb(228, 227, 227);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-width: 0px;
  transition: transform .2s ease;
}

.day-drag-card:hover {
  -ms-transform: scale(1.03); /* IE 9 */
  -webkit-transform: scale(1.03); /* Safari 3-8 */
  transform: scale(1.03) //translateX(-1rem) scale(1.03); 
}

.flash {
  animation-name: color_change;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: initial;
}

@keyframes color_change {
  from {
      background-color:#E6E6E6;
  }

  to {
      background-color: none;
  }
}

.fade-scale {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;
}

.fade-scale.show {
  opacity: 1;
  transform: scale(1);
}

.fade-scale {
  -webkit-backdrop-filter: blur(3px);  /* Safari 9+ */
  backdrop-filter: blur(3px); /* Chrome and Opera */
  // box-shadow: inset 0 0 0 200px rgba(255,255,255,0.08);
}

.width-90{
  min-width: 90%;
}

// .zoom {
//   padding: 50px;
//   background-color: green;
//   width: 200px;
//   height: 200px;
//   margin: 0 auto;
// }

// .zoom:hover {
//   -ms-transform: scale(1.03); /* IE 9 */
//   -webkit-transform: scale(1.03); /* Safari 3-8 */
//   transform: translateX(-1rem) ; 
// }